export default {
  // vuex中的store分模块管理，需要在store的index.js中引入各个模块，为了解决不同模块命名冲突的问题，将不同模块的namespaced:true，可以实现分离模板间的独立。
  namespaced: true,
  state: {
    admin: [
      {
        title: "首页",
        icon: "el-icon-menu",
        path: "admin",
        children: [
          {
            title: "首页",
            path: "Index",
          },
        ],
      },
      {
        title: "账号管理",
        path: "admin",
        icon: "el-icon-s-custom",
        children: [
          {
            title: "账号信息",
            path: "UserInfo",
          },
          {
            title: "业务员",
            path: "SalesmanInfo",
          },
        ],
      },
      {
        title: "招商管理",
        path: "admin",
        icon: "el-icon-discount",
        children: [
          // {
          //   title: "分类管理",
          //   path: "EntryCategory",
          // },
          {
            title: "商家信息",
            path: "BusinessInfo",
          },
          {
            title: "渠道信息",
            path: "HotelInfo",
          },
          {
            title: "加盟信息",
            path: "UnionInfo",
          },
        ],
      },
      {
        title: "订单管理",
        path: "admin",
        icon: "el-icon-tickets",
        children: [
          {
            title: "订单信息",
            path: "OrderInfo",
          },
          {
            title: "订单分佣",
            path: "Commission",
          },
        ],
      },
      {
        title: "结算管理",
        path: "admin",
        icon: "el-icon-tickets",
        children: [
          {
            title: "结算订单",
            path: "OrderSettledInfo",
          },
          {
            title: "结算记录",
            path: "SettledBatchInfo",
          },
        ],
      },
      {
        title: "交易管理",
        path: "admin",
        icon: "el-icon-coin",
        children: [
          {
            title: "收款记录",
            path: "PaymentRecord",
          },
          {
            title: "提现信息",
            path: "TXInfo",
          },
          {
            title: "退款信息",
            path: "RefundInfo",
          },
          {
            title: "分佣记录",
            path: "CommissionRecord",
          },
        ],
      },
      {
        title: "消息管理",
        path: "admin",
        icon: "el-icon-chat-line-square",
        children: [
          {
            title: "系统消息",
            path: "SystemMsg",
          },
        ],
      },
      {
        title: "服务管理",
        path: "admin",
        icon: "el-icon-first-aid-kit",
        children: [
          {
            title: "服务项目",
            path: "ServiceObject",
          },
        ],
      },
      {
        title: "反馈管理",
        path: "admin",
        icon: "el-icon-edit-outline",
        children: [
          {
            title: "用户申述",
            path: "UserAppeal",
          },
        ],
      },
      {
        title: "获客管理",
        path: "tiktok",
        name: "Tiktok",
        icon: "el-icon-warning-outline",
        children: [
          {
            title: "D音获客配置",
            path: "getCustomer",
          },
        ],
      },
    ],
    superAdmin: [
      // {
      //   title: "首页",
      //   icon: "el-icon-menu",
      //   path: "admin",
      //   children: [
      //     {
      //       title: "首页",
      //       path: "Index",
      //     },
      //   ],
      // },
      {
        title: "开设账户",
        icon: "el-icon-s-home",
        path: "superAdmin",
        children: [
          {
            title: "开设账户",
            path: "OpenAccount",
          },
        ],
      },
      {
        title: "福利管理",
        path: "admin",
        icon: "el-icon-s-help",
        children: [
          {
            title: "优惠券",
            path: "Coupon",
          },
        ],
      },
      {
        title: "订单管理",
        path: "superAdmin",
        icon: "el-icon-tickets",
        children: [
          {
            title: "订单信息",
            path: "OrderInfo",
          },
        ],
      },
      {
        title: "结算管理",
        path: "superAdmin",
        icon: "el-icon-tickets",
        children: [
          {
            title: "结算信息",
            path: "OrderSettledInfo",
          },
        ],
      },
      {
        title: "配置管理",
        path: "admin",
        icon: "el-icon-setting",
        children: [
          {
            title: "基础配置",
            path: "BaseConfig",
          },
          {
            title: "权限配置",
            path: "PermissionConfig",
          },
        ],
      },
      // {
      //   title: "结算",
      //   icon: "el-icon-s-home",
      //   path: "superAdmin",
      //   children: [
      //     {
      //       title: "结算",
      //       path: "SettelMent",
      //     },
      //   ],
      // },
      {
        title: "日志管理",
        path: "superAdmin",
        icon: "el-icon-collection-tag",
        children: [
          {
            title: "接口日志",
            path: "InterfaceLog",
          },
          {
            title: "短信日志",
            path: "SmsLog",
          },
          {
            title: "接口监控",
            path: "InMonitor",
          }
        ],
      },
      {
        title: "错误记录",
        path: "superAdmin",
        name: "errLog",
        icon: "el-icon-warning-outline",
        children: [
        ],
      },
    ],
  },
  mutations: {
    changeAdmin(state, data) {
      // console.log(444444444,state, data);
    },
  },
};
