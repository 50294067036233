import Vue from "vue";
import Vuex from "vuex";
import navCollapse from "./navCollapse";
import menuData from "./menuData";
import userInfo from "./userInfo";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    navCollapse,
    menuData,
    userInfo,
  },
});
