import Vue from "vue";
// 清楚默认样式
import "reset.css";
import App from "./App.vue";
import router from "./router";
import "./plugins/element.js";

// 导入字体图标
import "./assets/fonts/iconfont.css";
// 导入全局样式表
import "./assets/css/global.css";

import "element-ui/lib/theme-chalk/index.css";
// import "./styles.scss";

import store from "./store";

import '@/common/autoRefresh'

Vue.config.productionTip = false;

// 修改标题
document.title = localStorage.getItem('name') || ''

import {setFavicon} from '@/common/common'

let logo
try{
  logo = JSON.parse(localStorage.getItem('roleInfo')).logo_img
}catch(err){
  logo = ''
}
// 调用函数，更改页签图标为指定的URL
setFavicon(logo);

new Vue({
  router,
  beforeCreate() {
    Vue.prototype.$bus = this;
  },
  render: (h) => h(App),
  store,
}).$mount("#app");
