import JSEncrypt from "jsencrypt";
import CryptoJS from "crypto-js";
import { sha256 } from "js-sha256";
import { PUBLIC_KEY } from "./config";
import Index from "@/components/index/Index.vue";
import router from "../router";
import store from "../store";

const KEY = 'bianhuangwaidexiyangjianjianhuanghun'

export const ED = (content) => {
  const encryptor = new JSEncrypt();
  encryptor.setPublicKey(PUBLIC_KEY);
  return encryptor.encrypt(content);
};

function getSha256(text) {
  return sha256(text);
}

function encrypt_str(key, iv, str) {
  const KEY = key;
  const IV = iv.substr(20, 16);
  var key = CryptoJS.enc.Utf8.parse(KEY); // 秘钥
  var iv = CryptoJS.enc.Utf8.parse(IV); // 向量iv
  const encrypted = CryptoJS.AES.encrypt(str, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
}
function decrypt_str(key, iv, str) {
  const KEY = key;
  const IV = iv.substr(20, 16);
  var key = CryptoJS.enc.Utf8.parse(KEY); // 秘钥
  var iv = CryptoJS.enc.Utf8.parse(IV); // 向量iv
  const decrypted = CryptoJS.AES.decrypt(str, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
}

export const setDataAes = (text) => {
  const key1 = getSha256(KEY).substr(10, 16);
  const iv1 = getSha256(key1);
  const ret1 = encrypt_str(key1, iv1, text);
  return ret1;
};

export const getDataAes = (text) => {
  var key = getSha256(KEY).substr(10, 16);
  const iv = getSha256(key);
  const ret = decrypt_str(key, iv, text);
  const ret_s = ret.replace(/^\s*|\s*$/g, "");
  return ret_s;
};

export const generateRouter = (role) => {
  let newChildrenRouter = [];
  // 通过遍历动态生成路由
  store.state.menuData[role].forEach((item) => {
    const ret = item.children.map((sitem) => {
      return {
        path: item.path + "/" + sitem.path,
        component: () => import(`../views/${item.path}/${sitem.path}.vue`),
        meta: {
          titles: [item.title, sitem.title],
        },
      };
    });
    newChildrenRouter = [...newChildrenRouter, ...ret];
  });


  // 将动态生成好的数组添加到路由组件中
  router.addRoute({
    path: "/",
    name: "index",
    component: Index,
    redirect: 'admin/Index',
    children: [],
  });
  // router.addRoute("父路由组件名称", "单个子路对象")
  // console.log(newChildrenRouter, 'newChildrenRouter');
  newChildrenRouter.forEach((item) => {
    router.addRoute("index", item)
  });
};

/**
 * 根据图片路径下载
 * @param imgsrc 图片路径
 * @param name 下载图片名称
 * @param type 格式图片，可选，默认png ，可选 png/jpeg
 */
export const downloadImage = (imgsrc, name, type = "png") => {
  const image = new Image();
  // 解决跨域 Canvas 污染问题
  image.setAttribute("crossOrigin", "anonymous");
  image.onload = function () {
    const canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext("2d");
    context?.drawImage(image, 0, 0, image.width, image.height);
    const url = canvas.toDataURL(`image/${type}`); // 得到图片的base64编码数据
    const a = document.createElement("a"); // 生成一个a元素
    const event = new MouseEvent("click"); // 创建一个单击事件
    a.download = name || "pic"; // 设置图片名称
    a.href = url; // 将生成的URL设置为a.href属性
    a.dispatchEvent(event); // 触发a的单击事件
  };
  // 将资源链接赋值过去，才能触发image.onload 事件
  image.src = imgsrc;
};

// a标签下载文件
export const AdownloadFile = (url) => {
  const a = document.createElement("a");
  a.href = url;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

// 时间戳变字符串
export const timestampToString = (timestamp) => {
    const date = new Date(timestamp); // 将时间戳转换为Date对象
    const year = date.getFullYear(); // 获取年份
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 获取月份并补零
    const day = date.getDate().toString().padStart(2, '0'); // 获取日期并补零
    const hours = date.getHours().toString().padStart(2, '0'); // 获取小时并补零
    const minutes = date.getMinutes().toString().padStart(2, '0'); // 获取分钟并补零
    const seconds = date.getSeconds().toString().padStart(2, '0'); // 获取秒钟并补零
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`; // 拼接字符串
  }


  // 设置页签图标的函数
  export function setFavicon(src) {
  if(!src) return
  // 创建一个新的link元素
  var link = document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = src;
 
  // 先移除旧的favicon
  try{
    var head = document.getElementsByTagName('head')[0];
    var oldLink = document.getElementById('dynamic-favicon');
    if (oldLink) head.removeChild(oldLink);

  }catch(err){

  }
 
  // 将新的link元素添加到head中
  head.appendChild(link);
  link.id = 'dynamic-favicon';
}