<template>
  <div class="layout">
    <NavBar />
    <div class="layout-right">
      <HeaderView @refresh="refresh" />
      <ContentView :isRefresh="isRefresh" />
    </div>
  </div>
</template>

<script>
import NavBar from "./NavBarView.vue";
import HeaderView from "./HeaderView.vue";
import ContentView from "./ContentView.vue";

export default {
  data() {
    return {
      isRefresh: true,
    };
  },
  components: {
    NavBar,
    HeaderView,
    ContentView,
  },
  methods: {
    refresh() {
      this.isRefresh = false;
      this.$nextTick(() => {
        let timer = setTimeout(() => {
          this.isRefresh = true;
          clearTimeout(timer);
        }, 300);
      });
    },

  },
};
</script>

<style lang="less" scoped>
.layout {
  width: 100%;
  height: 100%;
  display: flex;
  .layout-right {
    width: calc(100% - 200px);
    flex: 1;
  }
}
</style>
