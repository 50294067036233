<template>
  <div class="navbar" :class="{ isColl: isCollapse }">
    <div class="logo">
      <div class="mt10"><img :src="roleInfo.logo_img" alt="" /></div>
      <div class="mt10">
        <span v-show="!isCollapse">{{ roleInfo.name }}</span>
      </div>
    </div>
    <el-menu
      :default-active="$route.path"
      class="el-menu-vertical-demo"
      :collapse="isCollapse"
      :unique-opened="true"
      background-color="#304156"
      text-color="#fff"
      active-text-color="#ffd04b"
      :collapse-transition="false"
      :router="true"
    >
      <el-submenu
        v-for="(item, index) in menuData"
        :key="index"
        :index="item.path + index"
      >
        <template slot="title">
          <i :class="item.icon"></i>
          <span>{{ item.title }}</span>
        </template>
        <el-menu-item
          v-for="(item1, index1) in item.children"
          :key="index1"
          :index="'/' + item.path + '/' + item1.path"
          > {{ item1.title }}</el-menu-item
        >
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
import router from "@/router";
import { AdminGetErrorLogMenuApi } from "@/common/api";
import { mapMutations, mapState } from "vuex";
import { getDataAes } from "@/common/common"
export default {
  data() {
    return {
      roleInfo: {}
    };
  },
  computed: {
    ...mapState({
      isCollapse: (state) => state.navCollapse.isCollapse,
      // 登录的身份 1--管理员 / 2--超级管理员 / 3--贴牌商
      menuData: (state) => state.menuData[JSON.parse(getDataAes(localStorage.getItem('role'))).role == 2?"superAdmin":"admin"],
    }),
  },
  methods: {
    ...mapMutations({
      changeAdmin: "menuData/changeAdmin",
    }),
    async getErrmenu() {
      const res = await AdminGetErrorLogMenuApi();
      if (!res) return;
      // 通过遍历动态生成路由
      const errLog = this.menuData.find(item=>item.name==='errLog')
      let children = []
      const ret = res.data.map((item) => {
        children.push({
          title: item,
          path: item,
        },)
        return {
          path: "superAdmin/" + item,
          component: () => import(`@/views/superAdmin/ErrLog.vue`),
          meta: {
            titles: ['错误记录', item],
          },
        };
      });
      if(errLog != undefined){
        errLog.children = children
      }
      // router.addRoute("父路由组件名称", "单个子路对象")
      ret.forEach((item) => {
        router.addRoute("index", item);
      });
    },
  },
  mounted() {
    this.roleInfo = JSON.parse(localStorage.getItem('roleInfo'))
    this.getErrmenu();
  },
};
</script>

<style lang="less" scoped>
.navbar {
  width: 200px;
  height: 100%;
  box-shadow: 3px 0 3px #ccc;
  background: #304156;
  transition: all 0.3s;
  .el-menu {
    border: none;
  }

  // 表示同时有navbar、isColl时
  &.isColl {
    width: 64px;
  }
  .logo {
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
    img {
      width: 50px;
    }
  }

  .el-submenu .el-menu-item {
    background-color: rgb(38, 52, 69) !important;
  }
}
</style>
