import { instance, FormDataRequest } from "./request";

// 登录api
export const LoginApi = (params) =>
    instance.post("/admin/login", params);

// 登录账号信息api
export const LoginInfoApi = (params) =>
    instance.get("/admin/login", { params });

// 获取客户信息api
export const GetUserInfoApi = (params = {}) =>
    instance.get("/admin/user_info", { params });

// 管理员获取招商信息api
export const AdminGetBusinessInfoApi = (params) =>
    instance.get("/admin/business", { params });

// 管理员获取共享招商信息api
export const GetBusinessShareInfoApi = (params) =>
    instance.get("/admin/BusinessShare", { params });

// 修改是否使用外部商家api
export const ChangeUseInfoApi = (params) =>
    instance.put("/admin/BusinessShare", params);

// 管理员修改招商状态api
export const AdminUpdateBusinessStatusApi = (params) =>
    instance.put("/admin/business", params);

// 管理员获取基础配置api
export const AdminGetConfigureApi = () =>
    instance.get("/admin/configure");

// 管理员编辑基础配置api
export const AdminEditConfigureApi = (params) =>
    instance.put("/admin/configure", params);

// 管理员获取首页数据api
export const AdminIndexInfoApi = (params) =>
    instance.get("/admin/index", {params});

// 管理员获取服务项目数据api
export const AdminGetServiceObjectApi = () =>
    instance.get("/admin/service_object");


// 管理员添加服务项目api
export const AdminAddServiceObjectApi = (params) =>
    instance.post("/admin/service_object", params);

// 管理员编辑服务项目api
export const AdminEditServiceObjectApi = (params) =>
    instance.put("/admin/service_object", params);

// 管理员删除服务项目api
export const AdminDeleteServiceObjectApi = (params) =>
    instance.delete("/admin/service_object", { params });

// 管理员商家审核通过后进行短信通知api
export const AdminSmsNotifyApi = (params) =>
    instance.post("/admin/business", params);

// 管理员获取接口日志api
export const AdminGetInterfaceLogApi = (params) =>
    instance.get("/admin/interface_log", { params });

// 管理员获取短信日志api
export const AdminGetSmsLogApi = (params) =>
    instance.get("/admin/sms_log", { params });

// 管理员获取错误日志文件菜单api
export const AdminGetErrorLogMenuApi = () =>
    instance.get("/admin/error_log_menu");

// 管理员获取错误日志内容api
export const AdminGetErrorLogContentApi = (params) =>
    instance.post("/admin/error_log_menu", params);

// 管理员获取订单数据api
export const AdminGetOrderInfoApi = (params) =>
    instance.get("/admin/order_info", { params });

// 管理员获取待处理事件api
export const AdminGetPendProApi = () =>
    instance.get("admin/pending_processing");

// 管理员获取充值续费信息api
export const GetRechangeApi = () =>
    instance.get("/admin/SaaSRecharge");

// 管理员充值续费信息api
export const GoRechangeApi = (params) =>
    instance.post("/admin/SaaSRecharge", params);

// 获取是否续费成功api
export const getResultZfApi = (params) =>
    instance.get("/admin/SaaResult", { params });

// 获取到期时间api
export const GetDueTimeApi = () =>
    instance.get("/admin/GetDueTime");

// 获取三端二维码api
export const getH5EwmIamgeApi = () =>
    instance.get("/admin/GetH5EWM");

// 管理员获取订单分佣数据api
export const AdminGetOrderCommissionApi = (params) =>
    instance.get("admin/order_commission", { params });

// 管理员获取首页商家和客户数据api
export const AdminGetIndexBCApi = () =>
    instance.get("admin/index_business_custom");

// 管理员获取一条订单分佣的分佣详情api
export const AdminGetCommissionDetailApi = (params) =>
    instance.get("admin/commission_detail", { params });

// 管理员获取渠道信息api
export const AdminGetHotelInfoApi = (params) =>
    instance.get("/admin/hotel_info", { params });

// 管理员获取渠道推广信息api
export const AdminGetShareInfoApi = (params) =>
    instance.get("/admin/hotel_share", { params });

// 管理员设置渠道状态api
export const AdminSetStatusApi = (params) =>
    instance.put("/admin/hotel_info", params);

// 管理员删除渠道信息api
export const AdminDeleteHotelInfoApi = (params) =>
    instance.delete("/admin/hotel_info", { params });

// 管理员添加渠道信息api
export const AdminAddHotelInfoApi = (params) =>
    instance.post("/admin/hotel_add_edit", params);

// 管理员编辑渠道信息api
export const AdminEditHotelInfoApi = (params) =>
    instance.put("/admin/hotel_add_edit", params);


// 管理员获取优惠券信息api
export const AdminGetCouponApi = (params) =>
    instance.get("/admin/coupon_info", { params });

// 管理员添加优惠券信息api
export const AdminAddCouponApi = (params) =>
    instance.post("/admin/coupon_info", params);

// 管理员添加优惠券信息api
export const AdminEditCouponApi = (params) =>
    instance.put("/admin/coupon_info", params);

// 管理员添加优惠券信息api
export const AdminDeleteCouponApi = (params) =>
    instance.delete("/admin/coupon_info", { params });

// 管理员获取发放数据api
export const AdminGetGrantInfopi = (params) =>
    instance.get("/admin/grant_coupon", { params });

// 管理员发放优惠券api
export const AdminGrantCouponApi = (params) =>
    instance.post("/admin/grant_coupon", params);

// 管理员添加商户api
export const AdminAddBusinessApi = (params) =>
    instance.post("/myapp/business_recruit", params);

// 管理员修改商户api
export const AdminEditBusinessApi = (params) =>
    instance.put("/admin/business_info", params);

// 管理员获取提现信息api
export const AdminGetTXInfoApi = (params) =>
    instance.get("/admin/tx_info", { params });

// 管理员修改提现审核api
export const AdminUpdateTXApi = (params) =>
    instance.put("/admin/tx_info", params);


// 管理员获取业务员信息api
export const AdminGetSalesmanInfoApi = (params) =>
    instance.get("/admin/salesman_info", { params });

// 管理员获取业务员信息api
export const AdminCreateSalesmanApi = (params) =>
    instance.post("/admin/salesman_info", params);

// 管理员获取业务员信息api
export const AdminEditSalesmanApi = (params) =>
    instance.put("/admin/salesman_info", params);

// 管理员获取业务员信息api
export const AdminDeleteSalesmanApi = (params) =>
    instance.delete("/admin/salesman_info", { params });


// 管理员获取业务员信息api
export const AdminChangeSalesmanStatusApi = (params) =>
    instance.put("/admin/change_salesman_status", params);


// 管理员获取退款信息api
export const AdminGetTKInfoApi = (params) =>
    instance.get("/admin/tk_info", { params });

// 管理员审核退款信息api
export const AdminChangeTXStatusApi = (params) =>
    instance.put("/admin/tk_info", params);

// 管理员审核照片发送消息api
export const AdminPhotosSendMessageApi = (params) =>
    instance.post("/admin/photos_root", params);

// 管理员下架照片api
export const AdminDownPhotosApi = (params) =>
    instance.delete("/admin/photos_root", { params });

// 管理员获取要取消的订单数据api
export const AdminGetCancelOrderApi = (params) =>
    instance.get("/admin/cancel_order", { params });

// 管理员获取取消订单提交api
export const AdminSubCancelOrderApi = (params) =>
    instance.put("/admin/cancel_order", params);

// 管理员获取消息api
export const AdminGetSystemMsgApi = (params) =>
    instance.get("/admin/system_msg", { params });

// 管理员删除消息api
export const AdminDeleteSystemMsgApi = (params) =>
    instance.delete("/admin/system_msg", { params });

// 管理员搜索接收方信息api
export const AdminQueryAcceptInfoApi = (params) =>
    instance.get("/admin/accept", { params });


// 管理员发送消息api
export const AdminSendMsgApi = (params) =>
    instance.post("/admin/system_msg", params);

// 管理员获取分佣记录api
export const AdminGetCommissionRecordApi = (params) =>
    instance.get("/admin/commission_record", {params});


// 管理员获取加盟信息api
export const AdminGetUnionInfoApi = (params) =>
    instance.get("/admin/union_info", {params});


// 管理员删除加盟信息api
export const AdminDeleteUnionInfoApi = (params) =>
    instance.delete("/admin/union_info", {params});

// 管理员获取用户申述信息api
export const AdminGetAppealInfoApi = (params) =>
    instance.get("/admin/appeal_info", {params});

// 管理员删除用户申述信息api
export const AdminDeleteAppealInfoApi = (params) =>
    instance.delete("/admin/appeal_info", {params});

// 管理员已处理用户申述api
export const AdminProAppealInfoApi = (params) =>
    instance.put("/admin/appeal_info", params);

// 管理员获取收款记录api
export const AdminGetPaymentRecordApi = (params) =>
    instance.get("/admin/payment_record", {params});

// 管理员修改权限设置api
export const AdminPermissionConfigApi = (params) =>
    instance.put("/admin/permission_config", params);

// 管理员获取接口监控信息api
export const AdminGetIntMonitorApi = (params) =>
    instance.get("/admin/interface_monitor", { params });


// 贴牌商结算

// 获取批次号 -- 结算
export const GetBatchSettledInfoApi = (params) =>
    instance.get("/admin/SettledBatchInfo", { params });

// 管理员结算api
export const GetOrderSettledInfoApi = (params) =>
    instance.get("/admin/OrderSettledInfo", { params });

// 结算批次详情
export const getSettledBatchDetail = (params) =>
    instance.get("/admin/SettledBatchDetail", { params });

// 结算订单
export const GetOrderSettledInfo = (params) =>
    instance.get("/admin/OrderSettledInfo", { params });

// 结算订单
export const GoSettlementApi = (params) =>
    instance.post("/admin/SettledBatchInfo", params );

//==========================================================================


// 超级管理员获取结算批次信息api
export const SupGetOrderSettledInfo = (params) =>
    instance.get("/admin/AdminSettledInfo", { params });

// 超级管理员获取结算批次信息api
export const examinBatchSettelApi = (params) =>
    instance.put("/admin/AdminSettledInfo", params);

// 超级管理员获取贴牌商家信息api
export const GetTpInfoApi = (params) =>
    instance.get("/admin/AdminAccount", { params });

// 超级管理员新增贴牌商家信息api
export const AddTpInfoApi = (data) =>
    instance.post("/admin/AdminAccount", data);

// 超级管理员编辑贴牌商家信息api
export const EditTpInfoApi = (data) =>
    instance.put("/admin/AdminAccount", data);

// 超级管理员获取订单信息api
export const SuperAdminGetOrderInfoApi = (params) =>
    instance.get("/admin/SaasOrderInfo", {params});

//商家是否共享api
export const ChangeIsShareStatusApi = (data) =>
    instance.put("/admin/SetBusinessShare", data)
//==========================================================================



// 获客模块

// 增评论语api
export const AddCommentInfoApi = (data) =>
    instance.post("/admin/SaasComment", data);

// 删评论语api
export const DelCommentInfoApi = (params) =>
    instance.delete("/admin/SaasComment", {params});

// 改评论语api
export const EditCommentInfoApi = (data) =>
    instance.put("/admin/SaasComment", data);

// 查评论语api
export const GetCommentListApi = (params) =>
    instance.get("/admin/SaasComment", {params});

// 增任务api
export const AddTaskInfoApi = (data) =>
    instance.post("/admin/TasksInfo", data);

// 删任务api
export const DelTaskInfoApi = (params) =>
    instance.delete("/admin/TasksInfo", {params});

// 删任务api
export const EditTaskInfoApi = (data) =>
    instance.put("/admin/TasksInfo", data);

// 查任务列表api
export const GetTaskListApi = (params) =>
    instance.get("/admin/TasksInfo", {params});

// 获取在线浏览器个数api
export const GetChromeOnLine = () =>
    instance.get("/admin/ChromeOnLine");

// 开启获客任务api
export const StartTaskInfoApi = (data) =>
    instance.post("/admin/TasksRun", data);