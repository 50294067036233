// 生产环境地址：
const PRODUCTION_BASE_URL = "https://aishangmen.thinktome.com/asm";

// 开发环境地址：
// const DEVLOPMENT_BASE_URL = "http://192.168.0.149:8000";

const DEVLOPMENT_BASE_URL = 'https://aishangmen.thinktome.com/asm'

// const DEVLOPMENT_BASE_URL = 'http://127.0.0.1:8000'
// const DEVLOPMENT_BASE_URL = PRODUCTION_BASE_URL

export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? DEVLOPMENT_BASE_URL
    : PRODUCTION_BASE_URL;

// console.log(process.env, BASE_URL)
// 公钥
export const PUBLIC_KEY =
  "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDe2LtwUqagAWvCvlGxHda+IWhBxAzZushSlwooYRouXvYYGVKEz/XOyOfKOpkUk0EJrix0V53BuSU4G24nDcQ2ynPFm8IuNkYC09MNVqqSH9TFoMLiVOJwQHsAuEBUGSLfPwOJbWG9ws22LBqsbsoEWez+FMAaZSDuwA2699LqLwIDAQAB";

export const TENXUN_KEY = "4HABZ-WFJAW-VKMRS-3RIMD-N4BJT-AKBQ2";

export const IMGURL =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAaCAIAAABHMPnbAAAB60lEQVRIDc1Wv0sDMRT+Ko5dBLs4tFuh4FSEgpNT6SBIwcGxUzvZydHRsVM7dbJ/QEEFB/EPKBVKBxGknbzFwSsI4n6+NEm9xKTmDmsvHNy9ly8v3/uRl0sFQYBkjI1k0GAsHKhMkeuhM105582V7xDa4KaP5mdIBq5qKEpFalErnR5aUhvp3T7E0TYwQ+MWd0tXCqQF803FCFj4UUmzbUbHyBhxdiXzcAde2Y6QM8uokJWnArpbyA1wto+Td+w9KyGVRsQ7RlyVOFGCDGMSZC+D9mQ+Y/s2LFNV84X1oaq0S4YTRM5RGKigTvOa20AeXg3ZR+T68H9MaorxC1M0SpraKioJ4hGmXBhIaBZ4kaZl9dCBH2gIZ1FWkqAyvkf1FZUCLsAKItKgVV3N9TnRXReXQjspUSG9//BLbfK1LH7Sm5A18elYv0rNAn/f4uj8U39anmXusOaDmUq1p8FcRUp0E6i4whWcmUq4HytwKRhTwAruA6MDnN+iNWBPpGGmEskEB7O+zNvxjCnWliCelxjXQthnc1SiJqhYhhe2ijgJgr0Rx53xg/ri0rDYeBuyi+XaV6YNjV917/8kkSDjQXdkYei2sRIkqGRK8LTm7UjEAotxgkTjp7/XNQ667WkkqFb063CNsUlQVL4AhbCfTjUAZKUAAAAASUVORK5CYII=";
