import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/components/login/Login.vue";
import NotFind from "@/components/404/404.vue";
import { generateRouter } from "@/common/common";
import { getDataAes } from "@/common/common"

// 重写VueRouter的push方法，防止连续点击多次路由报错
const originPush = VueRouter.prototype.push; // 备份原push方法
VueRouter.prototype.push = function (location, resolve, reject) {
  // return routerPush.call(this, location).catch(err => err)
  if (resolve && reject) {
    // 如果传了回调函数，直接使用
    originPush.call(this, location, resolve, reject);
  } else {
    // 如果没有传回调函数，手动添加
    originPush.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "*",
    component: NotFind,
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// 路由守卫
router.beforeEach((to, from, next) => {
  // 防止篡改role和indexPath导致出问题
  let token = localStorage.getItem("token");
  if (!token) {
    localStorage.clear();
    if (to.path === "/login") {
      next();
      return;
    }
  }
  // 若访问登录界面时，判断token，有则进入首页
  if (to.path !== "/login" && !token) {
    localStorage.clear()
    next("/login");
    return;
  }
  // 超级管理员当前没有首页
  if (to.path === "/login" && token && JSON.parse(getDataAes(localStorage.getItem('role'))).role == 2) {
    next("/superAdmin/OpenAccount");
    return;
  }
  if (to.path === "/login" && token) {
    next("/");
    return;
  }
  // 判断'/'路由是否存在
  if (!router.getRoutes().some((item) => item.path === "")) {
    // 动态生成路由
    try{
      generateRouter(JSON.parse(getDataAes(localStorage.getItem('role'))).role == 2?"superAdmin":"admin");
      next(to.path);
      // 使用return不然它往下执行
      return;
    }catch{
      localStorage.clear()
      next("/login");
      // 使用return不然它往下执行
      return;
    }
  }
  next();
});

export default router;
