
// 更新页面

//=======根据script标签是否有变化来判断更新=================
// let lastScrs; // 上一次获取到的script地址
// const scriptReg = /\<script.*src=["'](?<src>[^"']+)/gm
// // 获取最新页面中的script链接
// async function extracNewScripts(){
//     const html = await fetch('/?_timestamp=' + new Date()).then((res)=>res.text())
//     scriptReg.lastIndex = 0
//     let result = []
//     let match
//     while((match = scriptReg.exec(html))){
//         result.push(match.groups.src)
//     }
//     return result
// }

// // 判断是否要更新
// async function needUpdate(){
//     const newScripts = await extracNewScripts();
//     if(!lastScrs){
//         lastScrs = newScripts;
//         return false
//     }
//     let result = false
//     if(lastScrs.length !== newScripts.length){
//         result = true
//     }
//     for(let i=0; i<lastScrs.length;i++){
//         if(lastScrs[i] !== newScripts[i]){
//             result = true;
//             break
//         }
//     }
//     lastScrs = newScripts;
//     return result
// }

//=======根据html文本是否有变化来判断更新=================
let htmlText

// 获取html
async function getHtml(){
    try {
        const res = await fetch('/?_timestamp=' + new Date())
        if (res.status === 200){
            return res.text()
        }
        return ''
    } catch (error) {
        return ''
    }

}

// 判断是否要更新
async function needUpdate(){
    let cacheHtml = localStorage.getItem('htmlText')
    if (!cacheHtml){
        cacheHtml = await getHtml()
        if(!cacheHtml){
            return false
        }
        localStorage.setItem('htmlText', cacheHtml)
    }
    htmlText = await getHtml()
    if(!htmlText || htmlText === cacheHtml){
        return false
    }
    return true
}

// 自动刷新
async function autoRefresh(){
    setTimeout(async ()=>{
        const willUpdate = await needUpdate()
        if(willUpdate){
            const result = confirm('页面有更新， 点击确定进行刷新')
            if(result){
                localStorage.removeItem('htmlText');
                location.reload()
            }
        }
        autoRefresh();
    }, 5000)
}
autoRefresh()
