<template>
	<div class="content">
		<transition name="my-transform" mode="out-in">
			<router-view v-if="isRefresh"></router-view>
		</transition>
	</div>
</template>

<script>
export default {
	props: ['isRefresh']
};
</script>

<style lang="less" scoped>
.content {
	position: relative;
	padding: 10px;
	overflow: hidden scroll;
	height: calc(100% - 90px);
	background: #f8f8f8;
}

/* 进场的开始效果 */
.my-transform-enter,
.my-transform-leave-to {
	opacity: 0;
	transform: translateX(10px);
}

/* 指定要过渡的动画 */
.my-transform-enter-active,
.my-transform-leave-active {
	transition: all 0.3s;
}

/* 进场的结算动画 和 离场的开始效果 */
.my-transform-enter-to,
.my-transform-leave {
	opacity: 1;
	transform: translateX(0px);
}
</style>
