<template>
  <div class="container">
    <div class="forms-container">
      <div class="signin-signup">
        <form action="#" class="sign-in-form">
          <h3 class="title">登录</h3>
          <el-form
            ref="loginFormRef"
            label-width="0px"
            :model="loginForm"
            :rules="loginFormRules"
            class="input-field2"
          >
            <!-- 身份选择 -->
            <!-- 用户名 -->
            <el-form-item prop="account" class="input-field1" style="margin-bottom: 22px !important;">
              <el-input
                v-model="loginForm.account"
                prefix-icon="iconfont icon-zhanghao"
                placeholder="用户名"
                round
                ref="searchInput1"
              />
            </el-form-item>
            <!-- 密码 -->
            <el-form-item prop="password" class="input-field1" style="margin-bottom: 22px !important;">
              <el-input
                v-model="loginForm.password"
                prefix-icon="iconfont icon-tianchongxing-"
                show-password
                placeholder="密码"
                round
                ref="searchInput2"
              />
            </el-form-item>
            <!-- 登录按钮 -->
            <div class="btns">
              <el-button type="primary" round @click="login"> 登录 </el-button>
            </div>
          </el-form>
        </form>
      </div>
    </div>

    <div class="panels-container">
      <div class="panel left-panel">
        <div class="content">
          <h3>哎 上 门 后 台 管 理</h3>
          <p>管 理 员 / 商 家</p>
        </div>
        <img src="../../assets/img/log.svg" class="image" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { LoginApi, LoginInfoApi } from "@/common/api";
import { mapActions } from "vuex";
import {setFavicon} from '@/common/common'

export default {
  name: "Login",
  data() {
    return {
      // 登录表单的数据绑定对象
      loginForm: {
        account: "",
        password: "",
      },
      // 表单的验证规则对象
      loginFormRules: {
        // 验证用户名是否合法
        account: [
          { required: true, message: "请输入账号", trigger: "blur" },
          {
            min: 5,
            max: 25,
            message: "长度在 5 到 25 个字符",
            trigger: "blur",
          },
        ],
        // 验证密码是否合法
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 5,
            max: 25,
            message: "长度在 5 到 25 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    ...mapActions({
      asyncChangeUserInfo: "userInfo/asyncChangeUserInfo",
    }),
    login() {
      this.$refs.loginFormRef.validate(async (valid) => {
        // valid是一个bool值，代表数据校验情况
        if (!valid) return;
        let res = await LoginApi({
            account: this.loginForm.account,
            password: this.loginForm.password
        });
        if (!res) return;
        // 登录的身份 1--管理员 / 2--超级管理员 / 3--贴牌商
        localStorage.setItem('role', res.data.role)
        localStorage.setItem('token', res.data.token)
        localStorage.setItem('name', res.data.name)
        // 修改标题
        document.title = res.data.name
        let resInfo = await LoginInfoApi()
        if(!resInfo) return
        localStorage.setItem('roleInfo', JSON.stringify(resInfo.data))
        let logo = resInfo.data.logo_img
        // 调用函数，更改页签图标为指定的URL
        setFavicon(logo);
        // this.$router.push("/");
        window.location.reload()
      });
    },
    // 回车键搜索
    enterLogin(event) {
        if (event.keyCode === 13) {
            let isfocus1 = document.activeElement === this.$refs.searchInput1.$refs.input;
            let isfocus2 = document.activeElement === this.$refs.searchInput2.$refs.input;
            // 判断光标是否聚焦在输入框中
            if (isfocus1 || isfocus2) {
                this.login();
            }
        }
    },
  },
  mounted() {
      // 监听回车事件
      document.addEventListener("keyup", this.enterLogin);
  },
  beforeDestroy() {
      document.removeEventListener("keyup", this.enterLogin);
  },
};
</script>

<style scoped>
.btns button,
.btns {
  width: 100%;
}
.el-radio-group {
  margin: 20px 0;
  transform: scale(1.2);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
input {
  font-family: "Poppins", sans-serif;
}

.container {
  position: relative;
  width: 100%;
  background-color: #fff;
  min-height: 100vh;
  overflow: hidden;
}

.forms-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.signin-signup {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 75%;
  width: 50%;
  transition: 1s 0.7s ease-in-out;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
}

form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0rem 5rem;
  transition: all 0.2s 0.7s;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

form.sign-up-form {
  opacity: 0;
  z-index: 1;
}

form.sign-in-form {
  z-index: 2;
}

.title {
  font-size: 2rem;
  color: #444;
  margin-bottom: 20px;
}

.input-field1 {
  max-width: 380px;
  width: 100%;
}

.input-field2 {
  max-width: 450px;
  width: 100%;
}
.social-text {
  padding: 0.7rem 0;
  font-size: 1rem;
}

.social-media {
  display: flex;
  justify-content: center;
}

.social-icon {
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.45rem;
  color: #333;
  border-radius: 50%;
  border: 1px solid #333;
  text-decoration: none;
  font-size: 1.1rem;
  transition: 0.3s;
}

.social-icon:hover {
  color: #4481eb;
  border-color: #4481eb;
}

.btn {
  width: 150px;
  background-color: #5995fd;
  border: none;
  outline: none;
  height: 49px;
  border-radius: 49px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
}

.btn:hover {
  background-color: #4d84e2;
}
.panels-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.container:before {
  content: "";
  position: absolute;
  height: 2000px;
  width: 2000px;
  top: -10%;
  right: 48%;
  transform: translateY(-50%);
  background-image: linear-gradient(-45deg, #4481eb 0%, #04befe 100%);
  transition: 1.8s ease-in-out;
  border-radius: 50%;
  z-index: 6;
}

.image {
  width: 100%;
  transition: transform 1.1s ease-in-out;
  transition-delay: 0.4s;
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  text-align: center;
  z-index: 6;
}

.left-panel {
  pointer-events: all;
  padding: 3rem 17% 2rem 12%;
}

.right-panel {
  pointer-events: none;
  padding: 3rem 12% 2rem 17%;
}

.panel .content {
  color: #fff;
  transition: transform 0.9s ease-in-out;
  transition-delay: 0.6s;
}

.panel h3 {
  font-weight: 600;
  line-height: 1;
  font-size: 1.5rem;
}

.panel p {
  font-size: 0.95rem;
  padding: 0.7rem 0;
}

.btn.transparent {
  margin: 0;
  background: none;
  border: 2px solid #fff;
  width: 130px;
  height: 41px;
  font-weight: 600;
  font-size: 0.8rem;
}

.right-panel .image,
.right-panel .content {
  transform: translateX(800px);
}

/* ANIMATION */

.container.sign-up-mode:before {
  transform: translate(100%, -50%);
  right: 52%;
}

.container.sign-up-mode .left-panel .image,
.container.sign-up-mode .left-panel .content {
  transform: translateX(-800px);
}

.container.sign-up-mode .signin-signup {
  left: 25%;
}

.container.sign-up-mode form.sign-up-form {
  opacity: 1;
  z-index: 2;
}

.container.sign-up-mode form.sign-in-form {
  opacity: 0;
  z-index: 1;
}
form.sign-in-form {
  transform: scale(1.4);
}

.container.sign-up-mode .right-panel .image,
.container.sign-up-mode .right-panel .content {
  transform: translateX(0%);
}

.container.sign-up-mode .left-panel {
  pointer-events: none;
}

.container.sign-up-mode .right-panel {
  pointer-events: all;
}

@media (max-width: 870px) {
  .container {
    min-height: 800px;
    height: 100vh;
  }
  .signin-signup {
    width: 100%;
    top: 95%;
    transform: translate(-50%, -100%);
    transition: 1s 0.8s ease-in-out;
  }

  .signin-signup,
  .container.sign-up-mode .signin-signup {
    left: 50%;
  }

  .panels-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }

  .panel {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem 8%;
    grid-column: 1 / 2;
  }

  .right-panel {
    grid-row: 3 / 4;
  }

  .left-panel {
    grid-row: 1 / 2;
  }

  .image {
    width: 200px;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.6s;
  }

  .panel .content {
    padding-right: 15%;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.8s;
  }

  .panel h3 {
    font-size: 1.2rem;
  }

  .panel p {
    font-size: 0.7rem;
    padding: 0.5rem 0;
  }

  .btn.transparent {
    width: 110px;
    height: 35px;
    font-size: 0.7rem;
  }

  .container:before {
    width: 1500px;
    height: 1500px;
    transform: translateX(-50%);
    left: 30%;
    bottom: 68%;
    right: initial;
    top: initial;
    transition: 2s ease-in-out;
  }

  .container.sign-up-mode:before {
    transform: translate(-50%, 100%);
    bottom: 32%;
    right: initial;
  }

  .container.sign-up-mode .left-panel .image,
  .container.sign-up-mode .left-panel .content {
    transform: translateY(-300px);
  }

  .container.sign-up-mode .right-panel .image,
  .container.sign-up-mode .right-panel .content {
    transform: translateY(0px);
  }

  .right-panel .image,
  .right-panel .content {
    transform: translateY(300px);
  }

  .container.sign-up-mode .signin-signup {
    top: 5%;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 570px) {
  form {
    padding: 0 1.5rem;
  }

  .image {
    display: none;
  }
  .panel .content {
    padding: 0.5rem 1rem;
  }
  .container {
    padding: 1.5rem;
  }

  .container:before {
    bottom: 72%;
    left: 50%;
  }

  .container.sign-up-mode:before {
    bottom: 28%;
    left: 50%;
  }
}
</style>
