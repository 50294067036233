<template>
  <div>

    <div class="header">
      <div class="header-top">
        <div class="fl">
          <el-button icon="el-icon-s-fold" v-show="isShow" @click="changShow"></el-button>
          <el-button icon="el-icon-s-unfold" v-show="!isShow" @click="changShow"></el-button>
        </div>
        <div class="fl">
          <Crumb></Crumb>
        </div>
        <div class="fr">

          <!-- h5入口 -->
          <div class="mr30 flex felxA" v-if="!!imagelist.length>0">
            <div v-for="item in imagelist" class="mr15" style="display: flex; flex-direction: column; align-items: center;">
              <div style="font-size: 12px; margin-bottom: -24px;">{{ item.name }}</div>
              <el-popover placement="bottom" trigger="click" width="160">
                <div>
                  <img :src="item.img" style="width: 120px; height: 120px;"/>
                  <div>{{ item.url }}</div>
                </div>
                <img :src="item.img" slot="reference" style="width: 20px; height: 20px;"/>
              </el-popover>
            </div>
          </div>

          <!-- 到期展示 -->
          <div>
            <div class="mr30 flex flexA" v-if="role != 2 && !!dueTime.due_time && !isOverTime">
              <div class="mr10" style="color: green;">{{ dueTime.due_time }} 到期</div>
              <el-button type="primary" style="width: 60px; height: 30px; font-size: 16px;" @click="renew"
                v-if="!dueTime.isLife">续费</el-button>
            </div>
            <div v-if="role != 2 && dueTime.due_time == '' && !dueTime.isLife" class="mr30" style="color: red; cursor: pointer;" @click="renew">还未充值，前往充值</div>
            <div v-if="isOverTime" @click="renew" style="color: red;">已经过期，请续费后使用</div>
          </div>

          <el-dropdown @command="hdCommand">
            <div class="avatar">
              <img :src="image" alt="" />
              <span> {{ userInfo }}</span>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="logout"><i class="el-icon-switch-button"></i>退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="header-bottom">
        <Tags></Tags>
        <span style="
            position: absolute;
            right: 70px;
            top: 13px;
            cursor: pointer;
            display: flex;
          ">
          <div title="消息" @click="drawer = true">
            <el-badge :value="tipNum" class="item">
              <i class="el-icon-bell size-20"></i>
            </el-badge>
          </div>

          <div style="margin: 0 30px">
            <i @click="refresh" title="刷新" class="el-icon-refresh-right size-20"></i>
          </div>
          <div><i @click="full" title="全屏" class="el-icon-full-screen size-19"></i></div>
        </span>
      </div>
      <div class="messageTip">
        <el-drawer title="消息" :visible.sync="drawer" :with-header="false">
          <div class="messageDiv">
            <div class="messageTitle">消息</div>
            <div class="messageContent">
              <div v-for="(item, index) in messageData" :key="index" class="messageText" @click="Jump(item)">
                <img :src="item.icon" alt="" width="25" /><span v-html="item.msg"></span>
                <span v-show="item.num > 0" class="Spantip">{{ item.num }}</span>
              </div>
              <div v-if="messageData.length == 0">暂无消息~</div>
            </div>
          </div>
        </el-drawer>
      </div>
    </div>

    <!-- 续费弹窗 -->
    <el-dialog title="续费" :visible.sync="dialogFormVisible" width="600px" :close-on-click-modal="false">
      <div class="xfInfo" v-loading="loading">
        <div class="xfMsg" v-for="(item, index) in xfData" :key="item.time" :class="index == currentIndex ? 'xfBg' : ''"
          @click="choseTime(index)">
          <div class="time">{{ item.recharge_id == 'half' ? '6 个 月' : item.recharge_id == 'year' ? '12 个 月' : '终 身' }}</div>
          <div class="mt30 mb20">
            <label class="monetLabel">￥</label>
            <label class="money">{{ item.price }}</label>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" class="mr10" @click="dialogFormVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="sureRenew">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 扫码支付弹窗 -->
    <el-dialog :visible.sync="showPic" width="400px" :close-on-click-modal="false">
      <div style="text-align: center;" v-if="!isPay">
        <img :src="reChangeData.image_data" style="width: 300px; height: 300px;" />
        <div class="ptb20">请扫码付款</div>
      </div>
      <div v-else style="text-align: center;">
        <div class="el-icon-success" style="font-size: 40px; color: green;"></div>
        <div>已成功续费</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import Crumb from "./Crumb";
import Tags from "./Tags";
import { AdminGetPendProApi, GetRechangeApi, GoRechangeApi, getResultZfApi, GetDueTimeApi, getH5EwmIamgeApi } from "@/common/api";
import { getDataAes, timestampToString } from "@/common/common"
export default {
  data() {
    return {
      isShow: true,
      drawer: false,
      messageData: [],
      tipNum: "",
      userInfo: localStorage.getItem('name'),
      image: JSON.parse(localStorage.getItem('roleInfo')).logo_img,
      dialogFormVisible: false,
      xfData: [],   //续费信息
      currentIndex: 0,
      showPic: false,
      reChangeData: {},
      isZf: '',
      isPay: false,
      loading: false,
      dueTime: '',
      role: JSON.parse(getDataAes(localStorage.getItem('role'))).role,
      nowData: '',
      isOverTime: false,
      imagelist: []
    };
  },
  components: {
    Crumb,
    Tags,
  },
  watch: {
    $route: {
      handler() {
        this.getProMessage();
      },
      immediate: true,
    },
  },
  mounted() {
    // 获取三端h5入口
    if (this.role != 2) {
      // h5三端入口
      this.getH5Image()
      this.nowData = timestampToString(Date.now()).split(' ')[0]
      // 获取到期时间
      this.getDueTime()
      // 获取续费套餐
      this.getRechange()
    }
  },
  watch: {
    showPic() {
      if (!this.showPic) {
        clearInterval(this.isZf)
      }
    }
  },
  methods: {
    async getH5Image(){
      this.imagelist = []
      let res = await getH5EwmIamgeApi()
      if(!res) return
      this.imagelist.push(
        {
          name: '用户端',
          img: res.data.client,
          url: res.data.client_url
        },
        {
          name: '商家端',
          img: res.data.business,
          url: res.data.business_url
        },
        {
          name: '业务端',
          img: res.data.salesman,
          url: res.data.salesman_url
        }
      )
    },
    // 获取到期时间
    async getDueTime() {
      let res = await GetDueTimeApi()
      if (!res) return
      this.dueTime = res.data
      localStorage.setItem('due_time', JSON.stringify(res.data))
      // 判断是否到期
      if(this.nowData<JSON.parse(localStorage.getItem('due_time'))){
        // 还没有到期
        this.isOverTime = false
      }else{
        this.isOverTime = true
      }
      localStorage.setItem('isOverTime', this.isOverTime)
    },
    full() {
      // 判断当前是不是全屏 true/false
      let full = document.fullscreenElement;
      if (!full) document.documentElement.requestFullscreen();
      else document.exitFullscreen();
    },
    Jump(item) {
      if (item.type == 10) {
        //管理员商家待审核
        this.drawer = false;
        this.$router.replace(
          `/admin/BusinessInfo?type=${item.type}&status=${item.key}`,
          () => { },
          () => { }
        );
      } else if (item.type == 20) {
        //管理员渠道待审核
        this.drawer = false;
        this.$router.push(
          `/admin/HotelInfo?type=${item.type}&status=${item.key}`
        );
      } else if (item.type == 30) {
        //管理员提现待审核
        this.drawer = false;
        this.$router.push(
          `/admin/TXInfo?type=${item.type}&status=${item.key}`
        );
      }
      else if (item.type == 40) {
        //管理员退款待审核
        this.drawer = false;
        this.$router.push(
          `/admin/RefundInfo?type=${item.type}&status=${item.key}`
        );
      }
    },
    ...mapMutations({
      changeIsCollapse: "navCollapse/changeIsCollapse",
    }),
    changShow() {
      this.isShow = !this.isShow;
      this.changeIsCollapse();
    },
    hdCommand(command) {
      if (command === "logout") {
        localStorage.clear();
        this.$router.push("/login");
      }
    },
    // 获取待处理消息
    async getProMessage() {
      let res = await AdminGetPendProApi();
      if (!res) return;
      this.messageData = res.data;
      this.tipNum = res.total || "";
    },
    // 刷新
    refresh() {
      this.$emit('refresh')
    },
    // 续费
    renew() {
      this.dialogFormVisible = true
    },
    // 点击选择续费套餐
    choseTime(index) {
      this.currentIndex = index
      this.recharge_id = this.xfData[index].recharge_id
    },
    // 获取充值续费信息
    async getRechange() {
      let res = await GetRechangeApi();
      if (!res) return;
      this.xfData = res.data
      this.recharge_id = this.xfData[0].recharge_id
    },
    // 确认充值
    async sureRenew() {
      this.loading = true
      let res = await GoRechangeApi({
        recharge_id: this.recharge_id
      });
      this.loading = false
      if (!res) return
      this.reChangeData = res.data
      if (this.reChangeData) {
        this.dialogFormVisible = false
        this.showPic = true
        this.isZf = setInterval(async () => {
          let result = await getResultZfApi({
            saas_order_id: this.reChangeData.saas_order_id,
            noTip: true
          })
          if (result?.isPay) {
            this.isPay = true
            clearInterval(this.isZf)
            this.showPic = false
            // alert('支付成功')
            this.$message.success('已支付成功')
            setTimeout(()=>{
              location.reload()
            },2000)
          }
        }, 2000);
      }
    },

  },
};
</script>

<style lang="less" scoped>
.messageDiv {
  height: 100%;
  overflow: hidden;
}

.messageContent {
  padding: 10px 20px;
  height: inherit;
  overflow: hidden scroll;
}

.messageText:hover {
  background: #f2f2f2;
}

.messageText {
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 5px;
  padding-top: 15px;
  font-size: 15px;
  color: #666;
  position: relative;
  cursor: pointer;

  img {
    vertical-align: sub;
  }

  .Spantip {
    background: red;
    color: white;
    border-radius: 50%;
    min-width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    display: inline-block;
    transform: scale(0.9);
    font-size: 14px;
    position: absolute;
    top: 7px;
    right: 10px;
  }
}

.messageTitle {
  height: 35px;
  border-bottom: 1px solid #f2f2f2;
  line-height: 35px;
  padding-left: 10px;
}

.header {
  button {
    width: 50px;
    height: 50px;
    margin: 0;
    font-size: 24px;
    padding: 5px;
    border: none;
  }

  .header-top {
    height: 50px;
    box-shadow: 0px 5px 5px -5px #eee;
  }

  .header-bottom {
    height: 40px;
    position: relative;
    z-index: 8;
    box-shadow: 0 5px 5px -5px #dbdbdb;
  }

  .fl {
    float: left;
  }

  .fr {
    display: flex;
    align-items: center;
    float: right;
    height: 50px;
    line-height: 50px;

    .avatar {
      margin-right: 50px;

      img {
        width: 30px;
        vertical-align: middle;
      }

      span {
        user-select: none;
      }

      :hover {
        cursor: pointer;
      }
    }
  }
}

.el-dropdown-menu {
  transform: translateX(-30px);
}

.xfInfo {
  display: flex;
  align-items: center;
  justify-content: space-around;

  .xfMsg {
    padding: 30px;
    display: grid;
    place-items: center;
  }

  .time {
    font-weight: 700;
    font-size: 18px;
  }

  .monetLabel {
    color: #ED6E2F;
    margin-bottom: 20px;
  }

  .money {
    font-weight: 700;
    font-size: 24px;
    color: #ED6E2F;
  }
}

.xfBg {
  border-radius: 10px;
  border: 4px solid #F4D18B;
}
</style>
