export default {
  // vuex中的store分模块管理，需要在store的index.js中引入各个模块，为了解决不同模块命名冲突的问题，将不同模块的namespaced:true，可以实现分离模板间的独立。
  namespaced: true,
  state: {
    isCollapse: false,
  },
  mutations: {
    changeIsCollapse(state) {
      state.isCollapse = !state.isCollapse;
    },
  },
};
