<template>
  <div class="crumb">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>活动管理</el-breadcrumb-item> -->
      <el-breadcrumb-item>{{ titles[0] }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ titles[1] }}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // titles的值与当前所在的路由相关
      titles: [],
    };
  },
  watch: {
    $route: {
      immediate: true, // 立即监听
      handler(val, oldval) {
        this.titles = this.$route.meta.titles;
      },
    },
  },
};
</script>

<style lang="less" scoped>
.crumb .el-breadcrumb {
  line-height: 50px;
  font-size: 15px;
}
</style>
