// 对axios进行二次封装
import axios from "axios";
// 引入进度条
import nprogress from "nprogress";
// start进度条开始，done进度条结束
import { ED, getDataAes } from "./common";
// 引入进度条样式
import "nprogress/nprogress.css";

import { Message } from "element-ui";

import router from "../router";
import { BASE_URL } from "./config";

let cancel = null;

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 30000, // 设置超时时间 30秒
});

let request_list = [];
// 请求拦截器
instance.interceptors.request.use((config) => {
  nprogress.start();
  config.headers.token = localStorage.getItem("token");
  config.headers.TimeStamp = new Date().getTime();
  // 创建一个cancelToken，并将其参数设置为一个executor函数
  config.cancelToken = new axios.CancelToken(function executor(c) {
    cancel = c;
  });
  // 检测上一次请求是否已经完成
  if (request_list.includes(config.url)) {
    // 取消上一次请求
    cancel();
  }
  // get请求不限制
  config.method !== 'get' && request_list.push(config.url);

  return config;
});

// 响应拦截器
instance.interceptors.response.use(
  (res) => {
    cancel = null;
    nprogress.done();
    request_list = request_list.filter(item => item !== res.config.url)
    if (!res.data) {
      Message.error("网络响应错误");
      return;
    }
    if (!res.data.code) {
      Message.error("网络响应错误");
      return;
    }
    res.data.code = res.data.code.toString();
    if (res.data.code === "200") {
      if (res.data.msg) {
        !res.config?.params?.noTip && Message.success(res.data.msg);
      }
      console.log(res.data);
      return res.data;
    } else {
      if (res.data.code == "401") {
        localStorage.clear();
        Message.error("登录已失效");
        router.push("/login");
        return;
      }
      !res.config?.params?.noTip && Message.error(res.data.msg || "网络请求错误");
    }
  },
  (err) => {
    request_list = [];
    cancel = null;
    nprogress.done();
    const { code, message } = err
    if (axios.isCancel(err) || code === 'ERR_CANCELED') {
      // 请求已取消, 不操作 request_list列表
      return;
    }
    if (code === "ECONNABORTED") {
      // 请求超时
      Message.error("请求超时");
      return;
    }
    // ERR_NETWORK
    if (message === "Network Error") {
      // 服务连接失败
      Message.error("服务连接失败");
      return
    }
    Message.error("服务请求错误");
  }
);

const FormDataRequest = (url, param) => {
  return instance({
    url: url,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: param,
    isFormData: true,
  });
};
export { instance, FormDataRequest };
