<template>
  <div>404！找不到页面，即将跳转回首页 {{ pnts }}</div>
</template>

<script>
export default {
  data() {
    return {
      pnts: "",
    };
  },
  created() {
    let times = setInterval(() => {
      this.pnts += ".";
      if (this.pnts === ".....") {
        this.pnts = "";
      }
    }, 300);
    setTimeout(() => {
      clearInterval(times);
      this.$router.push("/");
    }, 3000);
  },
};
</script>

<style lang="less" scoped></style>
