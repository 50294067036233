<template>
  <div class="tags tagDiv">
    <el-tag
      class="tag"
      size="medium"
      :closable="index > 0"
      v-for="(item, index) in tags"
      :key="item.path"
      :effect="
        item.title == $route.meta.titles[$route.meta.titles.length - 1]
          ? 'dark'
          : 'plain'
      "
      @click="goTo(item.path)"
      @close="close(index)"
      :disable-transitions="true"
      @contextmenu.native.prevent="rightClick($event, index)"
    >
      <i
        class="cir"
        v-show="item.title == $route.meta.titles[$route.meta.titles.length - 1]"
      ></i>
      {{ item.title }}</el-tag
    >
    <!-- <TagsMenu
      v-show="isShowTagsMenu"
      :clientX="clientX"
      :clientY="clientY"
      :clickIndex="clickIndex"
      :tagsLenght="tags.length"
      @fn="clickMenu"
    /> -->
  </div>
</template>

<script>
// import TagsMenu from "./TagsMenu.vue";
import { getDataAes } from "@/common/common"
export default {
  data() {
    return {
      tags: [],
      // isShowTagsMenu: false,
      // clientX: 0,
      // clientY: 0,
      // clickIndex: 0,
    };
  },
  components: {
    // TagsMenu,
  },
  watch: {
    $route: {
      immediate: true, // 立即监听
      handler(val, oldval) {
        let path =  val.path
        if(path.indexOf('?') > 0)
            path = path.slice(0, path.indexOf('?'))
        console.log('path: ', path, val.path);
        if (!this.tags.find((item) => path === item.path)) {
          this.tags.push({
            title: val.meta.titles[val.meta.titles.length - 1],
            path,
          });
        }
      },
    },
  },
  mounted() {
    this.tags = []
    // 超级管理员
    if(JSON.parse(getDataAes(localStorage.getItem('role'))).role == 2){
      this.tags.push({
          title: "开设账户",
          path: "/superAdmin/OpenAccount"
      })
    }else{
      this.tags.push({
          title: "首页",
          path: "/admin/Index"
      })
    }
    document.addEventListener("click", this.closeMenu);
  },
  methods: {
    // clickMenu(index) {
    //   if (index == 5) {
    //     // 全部关闭
    //     this.tags = [
    //       {
    //         title: "首页",
    //         path: "/" + localStorage.getItem("indexPath"),
    //       },
    //     ];
    //     this.goTo("/");
    //   }
    // },
    closeMenu() {
      this.isShowTagsMenu = false;
    },
    goTo(path) {
      this.closeMenu();
      this.$router.push(path);
    },
    close(index) {
      this.closeMenu();
      // 判断当前项是不是已选择的,并且不是最后一项
      if (
        this.tags[index].path == this.$route.path &&
        index !== this.tags.length - 1
      ) {
        // 跳转到最后一项
        this.$router.push(this.tags[this.tags.length - 1].path);
      } else if (index === this.tags.length - 1) {
        this.$router.push(this.tags[this.tags.length - 2].path);
      }
      // 关闭当前项
      this.tags.splice(index, 1);
    },
    rightClick(e, i) {
      // console.log(this.tags);
      // console.log('右键点击了');
      // this.isShowTagsMenu = true;
      // this.clientX = e.clientX;
      // this.clientY = e.clientY;
      // this.clickIndex = i;
      // 关闭浏览器默认菜单
      window.event.returnValue = false;
      return false;
    },
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closeMenu);
  },
};
</script>

<style lang="less" scoped>
.tags {
  padding: 7px 0 0 20px;

  .tag {
    margin-right: 5px;
    cursor: pointer;
    border-radius: 2px;
    .cir {
      width: 8px;
      height: 8px;
      margin-right: 4px;
      background: #fff;
      border-radius: 50%;
      display: inline-block;
    }
  }
}
</style>
